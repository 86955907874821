import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
} from "@apollo/client";

import App from "./App";
import Refresh from "components/Refresh";
import { Stack } from "@mui/system";
const httpLink = createHttpLink({ uri: "/graphql" });

const SENTRY_DSN = import.meta.env.SENTRY_DSN;
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.2,
    release: import.meta.env.SENTRY_RELEASE || "dev",
  });
}

// Error handler
const errorHandlerMiddleware = onError(
  ({ graphQLErrors = [] as any, networkError = {} as any }): void => {
    const statusCode: number =
      networkError && networkError.statusCode ? networkError.statusCode : null;
    if (statusCode === 503) {
      window.location.reload();
    } else if (statusCode === 403) {
      window.location.replace(`/sso/login?next=${window.location.pathname}`);
    }
    if (graphQLErrors && graphQLErrors.length > 0) {
      try {
        const graphQLErrorMessage = JSON.parse(graphQLErrors[0].message);
        if (graphQLErrorMessage && graphQLErrorMessage.statusCode) {
          if (graphQLErrorMessage.statusCode === 403) {
            window.location.replace(
              `/sso/login?next=${window.location.pathname}`,
            );
          } else if (graphQLErrorMessage.statusCode === 405) {
            window.location.replace("/search");
          } else {
            window.location.replace("/notauthorized");
          }
        }
      } catch (error) {
        // unhandled graphql error
      }
    }
  },
);

// Afterware executed from Apollo
const link = ApolloLink.from([errorHandlerMiddleware, httpLink]);

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      TemplateType: {
        fields: {
          doses: {
            merge(_, incoming) {
              return [...incoming];
            },
          },
        },
      },
    },
  }),
  link: link,
  connectToDevTools: true,
});

const ErrorFallback = (): JSX.Element => {
  return (
    <Stack justifyContent={"center"} alignItems={"center"}>
      An issue has just occurred. Please refresh your browser. If the issue
      persists contact support
    </Stack>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  // TODO: There is a bug in code that causes network errors due to disabled templates being that will in turn cause the app to reload
  //<React.StrictMode>
  <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
    <ApolloProvider client={client}>
      <Refresh />
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </Sentry.ErrorBoundary>,
  //</React.StrictMode>
);
