import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LandingPageButton from "./LandingPageButton";
import EditIcon from "@mui/icons-material/Edit";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArchiveIcon from "@mui/icons-material/Archive";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { Grid, List, Divider } from "@mui/material";
import ConfirmationDialog from "components/base/ConfirmationDialog";
import { APPROVE, TEST, DRAFT, beingEdited } from "i18n/constants";
import { useMutation } from "@apollo/client";
import { useTheme } from "@mui/material";

import {
  GET_CAREPLAN_TEMPLATE,
  ARCHIVE_TEMPLATE,
  UNARCHIVE_TEMPLATE,
  UPDATE_TEMPLATE_STATUS,
  DUPLICATE_TEMPLATE,
  EDIT_TEMPLATE,
  DISCARD_TEMPLATE,
} from "graphql/TemplateQueries";

interface Props {
  templateName: string;
  status: string;
  isActive: boolean;
  isLatestInGroup: boolean;
  templateId: string;
  canUserEdit: boolean;
  canUserDuplicate: boolean;
  isMo: boolean;
}

const LandingPageButtons = (props: Props) => {
  const {
    templateName,
    status,
    isActive,
    isLatestInGroup,
    templateId,
    canUserEdit,
    canUserDuplicate,
    isMo,
  } = props;
  const theme = useTheme();
  const history = useHistory();
  const disableEditing = !isActive || !isLatestInGroup || !canUserEdit;

  // Enable approve button if template is in test status, or if unarchiving (not actuve and latest in group)
  const enableApproveButton =
    (status === TEST || (!isActive && isLatestInGroup)) && canUserEdit;

  const [isArchiveVisible, setIsArchiveVisible] = useState(false);

  const viewTemplate = (templateId: string) =>
    history.push(`/template/${templateId}/information`);

  const [updateTemplateStatus] = useMutation(UPDATE_TEMPLATE_STATUS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
  });
  const [editTemplate] = useMutation(EDIT_TEMPLATE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
    onCompleted: (data) => {
      if (data && data.editTemplate && data.editTemplate.template)
        history.push(`/template/${data.editTemplate.template.id}/information`);
    },
  });
  const [duplicateTemplate] = useMutation(DUPLICATE_TEMPLATE, {
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data && data.duplicateTemplate && data.duplicateTemplate.template)
        history.push(`/template/${data.duplicateTemplate.template.id}`);
    },
  });
  const [archiveTemplate] = useMutation(ARCHIVE_TEMPLATE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
  });
  const [unarchiveTemplate] = useMutation(UNARCHIVE_TEMPLATE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
  });
  const [discardTemplate] = useMutation(DISCARD_TEMPLATE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
    onCompleted: (data) => {
      if (data?.discardTemplate?.template) {
        history.push(`/template/${data.discardTemplate.template.id}`);
        return;
      }
      // Template was newly created, so just return to search
      history.push(isMo ? "/protocol_search" : "/search");
    },
  });

  return (
    <List>
      <Grid item>
        <LandingPageButton
          customcolor={theme.palette.text.primary}
          data-test-id="btn-view"
          startIcon={<VisibilityIcon />}
          size="large"
          onClick={() => viewTemplate(templateId)}
        >
          View
        </LandingPageButton>
      </Grid>
      <Grid item>
        <LandingPageButton
          customcolor={theme.palette.text.primary}
          data-test-id="btn-edit"
          startIcon={<EditIcon />}
          size="large"
          disabled={disableEditing}
          onClick={(e: Event) => {
            //avoids editing the template twice.
            e.preventDefault();
            if (beingEdited(isActive, status)) {
              updateTemplateStatus({
                variables: { templateId, templateStatus: DRAFT },
              }).then(() =>
                history.push(`/template/${templateId}/information`),
              );
              return;
            }
            editTemplate({ variables: { templateId } });
          }}
        >
          {beingEdited(isActive, status) ? "Continue Editing" : "Edit"}
        </LandingPageButton>
      </Grid>
      {beingEdited(isActive, status) && (
        <Grid item>
          <LandingPageButton
            customcolor={theme.palette.error.main}
            data-test-id="btn-discard"
            startIcon={<RotateLeftIcon />}
            size="large"
            disabled={!canUserEdit}
            onClick={() => discardTemplate({ variables: { templateId } })}
          >
            Discard Changes
          </LandingPageButton>
        </Grid>
      )}
      <Grid item>
        <LandingPageButton
          customcolor={theme.palette.text.primary}
          data-test-id="btn-duplicate"
          startIcon={<FileCopyIcon />}
          size="large"
          disabled={!canUserDuplicate}
          onClick={() => duplicateTemplate({ variables: { templateId } })}
        >
          Duplicate
        </LandingPageButton>
      </Grid>
      <Grid item>
        <LandingPageButton
          customcolor={theme.palette.info.main}
          data-test-id="btn-validate"
          startIcon={<RateReviewIcon />}
          onClick={() =>
            updateTemplateStatus({
              variables: { templateId, templateStatus: TEST },
            })
          }
          size="large"
          disabled={status !== DRAFT || !canUserEdit}
        >
          Test
        </LandingPageButton>
      </Grid>
      <Divider
        style={{ width: "200px", marginTop: "16px", marginBottom: "16px" }}
      />
      <ConfirmationDialog
        title="Archive template?"
        bodyText={`Are you sure you want to archive template "${templateName}"? Once archived, it will no longer be available in the careplan template selection.`}
        confirmationText="Archive"
        cancelText="Cancel"
        setIsVisible={setIsArchiveVisible}
        isVisible={isArchiveVisible}
        onConfirmation={() =>
          archiveTemplate({ variables: { templateId, isActive: false } })
        }
      />
      <Grid item>
        <LandingPageButton
          customcolor={theme.palette.error.main}
          data-test-id="btn-archive"
          startIcon={<ArchiveIcon />}
          size="large"
          onClick={() => setIsArchiveVisible(true)}
          disabled={beingEdited(isActive, status) || disableEditing}
        >
          Archive
        </LandingPageButton>
      </Grid>
      <Grid item>
        <LandingPageButton
          customcolor={theme.palette.success.main}
          data-test-id="btn-approve"
          startIcon={<CheckCircleOutlineIcon />}
          size="large"
          onClick={() => {
            if (status === TEST) {
              updateTemplateStatus({
                variables: { templateId, templateStatus: APPROVE },
              });
            } else if (!isActive && isLatestInGroup) {
              unarchiveTemplate({
                variables: { templateId },
              });
            }
          }}
          disabled={!enableApproveButton}
        >
          Approve
        </LandingPageButton>
      </Grid>
    </List>
  );
};

export default LandingPageButtons;
