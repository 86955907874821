import { gql } from "@apollo/client";
export const GET_CAREPLAN_TEMPLATE = gql`
  query Template($templateId: ID!) {
    template(templateId: $templateId) {
      id
      name
      tumourStream {
        id
        name
        anatomicSiteNum
      }
      siteFilter
      techniqueFilter
      doseFilter
      isActive
      isLatestInGroup
      createdAt
      createdBy
      approvedBy
      editedAt
      status
      mrLinac
      isPlanningAutomationEnabled
      excludeFromSigmaRecommendations
      isMo
      isRo
      isTemporary
      evidenceId
      evidenceVersion
      evidenceLink
      continuous
      derivedFrom {
        id
        name
        createdAt
        status
        isActive
        isLatestInGroup
      }
      group {
        id
      }
      regions {
        id
        name
        subregions {
          id
          name
        }
      }
      subregions {
        id
        name
      }
      isNational
      templateRules {
        id
        field {
          id
          name
        }
        defaultValue
        isShown
        isActive
      }
      careplanTreatment {
        id
        name
        siteGroupRules {
          id
          field {
            id
            name
          }
          defaultValue
          isShown
          isActive
          createdBy
        }
        siteTemplate {
          id
          site {
            name
          }
          ruleSite {
            id
            field {
              id
              name
            }
            defaultValue
            isShown
            isActive
            createdBy
          }
        }
      }
      doses {
        id
        cycle
        day
        drugOrderInCycle {
          id
          protocolDrugOrder {
            id
          }
        }
        createdAt
      }
      numberOfCycles
      daysPerCycle
      negativeDays
    }
  }
`;

export const GET_TEMPLATE_VERSIONS = gql`
  query TemplateGroup($templateGroupId: ID!) {
    templateGroup(templateGroupId: $templateGroupId) {
      template {
        id
      }
    }
  }
`;

export const ARCHIVE_TEMPLATE = gql`
  mutation archiveTemplate($templateId: ID!, $isActive: Boolean!) {
    archiveTemplate(templateId: $templateId, isActive: $isActive) {
      template {
        id
        isActive
        status
      }
    }
  }
`;

export const UNARCHIVE_TEMPLATE = gql`
  mutation unarchiveTemplate($templateId: ID!) {
    unarchiveTemplate(templateId: $templateId) {
      template {
        id
        isActive
        isLatestInGroup
        status
      }
    }
  }
`;

export const UPDATE_TEMPLATE_STATUS = gql`
  mutation updateTemplate($templateId: ID!, $templateStatus: String!) {
    updateTemplate(templateId: $templateId, status: $templateStatus) {
      template {
        id
        isLatestInGroup
        isActive
        status
      }
    }
  }
`;

export const DUPLICATE_TEMPLATE = gql`
  mutation DuplicateTemplate($templateId: ID!) {
    duplicateTemplate(templateId: $templateId) {
      template {
        id
        isMo
        isRo
      }
    }
  }
`;

export const EDIT_TEMPLATE = gql`
  mutation editTemplate($templateId: ID!) {
    editTemplate(templateId: $templateId) {
      template {
        id
        isMo
        isRo
      }
    }
  }
`;

export const DISCARD_TEMPLATE = gql`
  mutation discardTemplate($templateId: ID!) {
    discardTemplate(templateId: $templateId) {
      template {
        id
        group {
          id
          templates {
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_TEMPLATE_INFORMATION = gql`
  mutation UpdateTemplate(
    $templateId: ID!
    $name: String
    $siteFilter: String
    $doseFilter: String
    $techniqueFilter: String
    $tumourStream: String
    $subregions: String
    $mrLinac: Boolean
    $isPlanningAutomationEnabled: Boolean
    $excludeFromSigmaRecommendations: Boolean
    $evidenceId: String
    $evidenceVersion: String
    $evidenceLink: String
    $numberOfCycles: Int
    $daysPerCycle: Int
    $negativeDays: [Int]
    $continuous: Boolean
  ) {
    updateTemplate(
      templateId: $templateId
      name: $name
      siteFilter: $siteFilter
      doseFilter: $doseFilter
      techniqueFilter: $techniqueFilter
      tumourStream: $tumourStream
      subregions: $subregions
      mrLinac: $mrLinac
      isPlanningAutomationEnabled: $isPlanningAutomationEnabled
      excludeFromSigmaRecommendations: $excludeFromSigmaRecommendations
      evidenceId: $evidenceId
      evidenceVersion: $evidenceVersion
      evidenceLink: $evidenceLink
      numberOfCycles: $numberOfCycles
      daysPerCycle: $daysPerCycle
      negativeDays: $negativeDays
      continuous: $continuous
    ) {
      template {
        id
      }
    }
  }
`;

export const UPDATE_TEMPLATE_FIELDS = gql`
  mutation UpdateTreatmentRuleTemplate(
    $fieldName: String!
    $templateId: ID!
    $defaultValue: String!
    $isShown: Boolean
  ) {
    updateTreatmentRuleTemplate(
      fieldName: $fieldName
      templateId: $templateId
      defaultValue: $defaultValue
      isShown: $isShown
    ) {
      treatmentRuleTemplate {
        id
        field {
          id
          name
        }
        defaultValue
        isShown
        isShown
        createdBy
      }
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation createTemplate(
    $region: String!
    $isMo: Boolean!
    $isRo: Boolean!
    $isTemporary: Boolean
  ) {
    createTemplate(
      region: $region
      isMo: $isMo
      isRo: $isRo
      isTemporary: $isTemporary
    ) {
      template {
        id
        name
        createdBy
        isMo
        isRo
        isTemporary
        careplanTreatment {
          id
        }
      }
    }
  }
`;
